<template>
    <div>
        <div v-if="value">
            <slot name="prepend-multi"></slot>
            <slot name="multi-open-tag"></slot>
            <slot></slot>
            <slot name="multi-close-tag"></slot>
            <slot name="postpend-multi"></slot>
        </div>
        <div v-else>
            <slot name="prepend-single"></slot>
            <slot></slot>
            <slot bale="postpend-single"></slot>
        </div>
    </div>
</template>

<script >
export default{
    props:[ 'value' ]
}
</script>